/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2020-01-16 17:58:46
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-03-29 13:47:30
 * @Description: 临时处理解决brick无法umd,同意管理全集模块
 */
import Brick from "@heisea/brick"
import "@heisea/ca"
import "@heisea/ca/lib/read"
// import ErrLog from '@heisea/err-log'

function Init(Vue, singleSpa) {
    Vue.use(Brick)
    // 错误上报
    // if(process.env.NODE_ENV === 'production'){
    //     Vue.use(ErrLog, {
    //         project: `hx-web-static`,
    //     });
    // }
    const gotoFromHost = (path, router, appCenter) => {
        // 此参数由upms提供，由于目前的加载方式不能保证upms优先加载完成，故放到这里
        var fromHost = localStorage.getItem('fromHost');
        var isOut = path.indexOf('token=null') > -1; // 退出
        // 如果appCenter执行回应用中心如:portal跳到医药云
        if (fromHost || appCenter) {
            // 返回外部应用中心
            var url = fromHost;
            // 前往外边的应用中心
            if (!isOut && appCenter) {
                url = appCenter;
            }
            // 避免没有加协议的情况
            if (url.indexOf('//') === -1) {
                // 兼容参数缺少//
                url = '//' + url;
            }
            window.location.href = url + (isOut ? path : '');
        } else {
            router.$router.push(path);
        }
    }
    singleSpa.Self = {
        /**
         * 跳转到来源网站
         * @param {*} path 
         * @param {*} router 
         * @param {*} appCenter 前往外边/portal
         * 无权限跳转：gotoFromHost('?token=null', vue实例) 
         */
        gotoFromHost,
        gotoFormHost: gotoFromHost

    }

}
window.INIT = Init;

export default Init
